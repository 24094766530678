@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: #0b1e4b;
  /* font-family: sans-serif; */
  overflow-x: hidden;
  font-family: upgrade, Helvetica, Arial, sans-serif;
}
